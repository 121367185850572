<template>
  <v-img
    v-if="namespace === 'Home'"
    :height="$vuetify.breakpoint.xsOnly ? '100vh' : '70vh'"
    gradient="to top, rgba(255, 255, 255, .75), rgba(255, 255, 255, .75)"
    src="/static/hero.jpg"
  >
    <v-container
      fill-height
      fluid
      :px-12="$vuetify.breakpoint.mdAndUp"
    >
      <v-row align-center>
        <v-slide-x-transition appear>
          <v-col>
            <h1
              class="text-uppercase font-weight-normal mb-4"
              :class="[$vuetify.breakpoint.xsOnly ? 'display-1' : 'display-3']"
              style="font-family:'Skia'!important"
            >
              XTERRA<sup style="font-size:3.5rem"><span style="font-size:1.25rem;font-weight:bold;">®</span></sup>
            </h1>

            <div
              class="mb-4"
              :class="[$vuetify.breakpoint.xsOnly ? 'headline' : 'display-2 font-weight-light']"
              style="font-family:'Skia'!important"
            >
              DEVELOPMENT
            </div>

            <v-responsive
              max-width="600"
              class="mb-5"
              style="font-family:'Skia'!important"
            >
              DESIGN PREFECTION QUALITY ™
            </v-responsive>
          </v-col>
        </v-slide-x-transition>
      </v-row>
    </v-container>
  </v-img>

  <v-img
    v-else
    :min-height="$vuetify.breakpoint.smAndDown ? '35vh' : '250px'"
    gradient="to top, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)"
    src="/static/hero.jpg"
    height="20vh"
  >
    <v-container fill-height>
      <v-row
        align-content="center"
        justify="center"
        class="fill-height"
      >
        <v-col cols="12">
          <h1
            class="display-1 text-center"
            style="font-family:'Skia'!important"
            v-text="title"
          />
        </v-col>

        <v-breadcrumbs
          :items="breadcrumbs"
          class="grey--text pb-0 px-3"
          style="font-family:'Skia'!important"
        />
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
  export default {
    computed: {
      breadcrumbs () {
        const home = { text: 'STRONA GŁÓWNA', to: '/' }

        switch (this.namespace) {
          case 'About': return [home, { text: 'O FIRMIE' }]
          case 'R21': return [home, { text: 'RÓŻANA 21' }]
          case 'B31': return [home, { text: 'BELWEDERSKA 31' }]
          case 'X1': return [home, { text: 'X1' }]
          case 'ZE': return [home, { text: 'Edmundowo Park' }]
          case 'Contact': return [home, { text: 'KONTAKT' }]
          default: return []
        }
      },
      namespace () {
        return this.$route.name
      },
      title () {
        switch (this.namespace) {
          case 'About': return 'XTERRA DEVELOPMENT'
          case 'B31': return 'XGBC - SEGMENT SZEREGOWCA'
          case 'R21': return 'XTERRA INSIDE - RÓŻANA 21'
          case 'X1': return 'XTERRA WOOD - model X1'
          case 'ZE': return 'Edmundowo Park'
          case 'Contact': return 'KONTAKT'
          default: return ''
        }
      }
    }
  }
</script>
